


































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import PersonDetail from '@/components/dashboard/PersonDetail/index.vue'
import PersonCard from '@/components/dashboard/PersonCard/index.vue'
import GaugeChart from '@/components/common/Echarts/GaugeChart.vue'
import FengMap from '@/components/common/FengMap/index.vue'
import GeoMap from '@/components/common/GeoMap/index.vue'
import HomeScreen from '@/components/dashboard/HomeScreen/index.vue'
import { getAttr, getDuration, timeType } from '@/utils'
//@ts-ignore
import Odometer from '@/assets/js/odometer.js'
import moment from 'moment'
import { actionTypes } from '@/store/realTime'
import AutoRotate from '@/views/Home/autoRotate.vue'
import { variables } from '@/libs/theme'
import { EventBus } from '@/utils/eventBus'
import { backTop } from '@/utils/dom'

@Component({
  name: 'homePage',
  components: {
    AutoRotate,
    PersonCard,
    GaugeChart,
    FengMap,
    GeoMap,
    PersonDetail,
    HomeScreen
  }
})
export default class HomePage extends Vue {
  private indexConfig: any = null
  private areas: any[] = []
  private realtime: AnyObj = {}
  private customerRateToday: any = null
  private customerRate7: any = null
  private customerStayTodayToday: any = null
  private customerStayToday7: any = null
  private curPerson: any = null
  private showDetailDialog = false
  private showBlackPop = false
  private isGeoMap = false
  private showMap = false
  private mapLoaded = false
  private options: any
  private timer: any = null
  private realtimeTotal = 0
  private realtimeNow: any = ''
  private capacity = 1000
  private domPosition = {
    button: { x: 346, y: 12, type: 'RIGHT_BOTTOM' },
    groupControl: { x: 304, y: 12, type: 'RIGHT_BOTTOM' }
  }
  private siderInBtn = false
  private currentAccount: string | null = ''
  private oneCard = ''
  private bfbWidth = ''
  private screenSelect = false
  private isSuccessLoad = false
  private mapTimer: any = null
  private startRotate = false
  private shadow9Color = variables.transparent
  private startRotateNode: any = null
  isHomePage = true

  async created() {
    EventBus.$on('personDetail', (val: any) => {
      this.curPerson = { ...val }
      this.showDetailDialog = true
    })
    this.getIndexConfig()
    this.customerStayTodaySeven()
    this.$store.dispatch(actionTypes.ACT_BASE_REALTIME_ADD, {
      fun: this.getTodayke,
      time: 60000,
      instance: this,
      name: 'count'
    })
    this.$store.dispatch(actionTypes.ACT_BASE_REALTIME_START, 3000)

    this.getTodayke()
  }
  mounted() {
    this.startRotateNode = document.getElementsByClassName('start-rotate')[0]
    this.currentAccount = localStorage.getItem('currentAccount')
    if (this.currentAccount) {
      this.currentStatus = JSON.parse(localStorage.getItem(this.currentAccount) as string)
    }
    if (!this.currentStatus) {
      this.currentStatus = {}
    }
    const boardStatus = this.currentStatus.boardStatus ? this.currentStatus.boardStatus : 'on'
    if (boardStatus === 'off') {
      this.signInBoardHide()
    }

    this.initOdometer()
  }

  activated() {
    this.isHomePage = true
    const name = this.$store.state.realTime.list.map((i: any) => i.name)
    if (!name.includes('count')) {
      this.getTodayke()
      this.$store.dispatch(actionTypes.ACT_BASE_REALTIME_ADD, {
        fun: this.getTodayke,
        time: 60000,
        instance: this,
        name: 'count'
      })
      this.$store.dispatch(actionTypes.ACT_BASE_REALTIME_START, 3000)
    }
    this.$store.state.dashboard.hideHomeMap = false
    if (
      this.indexConfig &&
      this.indexConfig.root.mapId &&
      !/geo:(\d{6})$/.test(this.indexConfig.root.mapId)
    ) {
      // 有地图才请求realtime
      if (!name.includes('realtime')) {
        this.getHeatingfe()
        this.$store.dispatch(actionTypes.ACT_BASE_REALTIME_ADD, {
          fun: this.getHeatingfe,
          time: 3000,
          instance: this,
          name: 'realtime'
        })
      }
      if (!this.isSuccessLoad) {
        this.showMap = false
        this.mapLoaded = false
        this.loadMap()
      }
    }
    this.heatingMessage = null
    this.todaykeMessage = null
  }

  deactivated() {
    this.isHomePage = false
    this.$store.dispatch(actionTypes.ACT_BASE_REALTIME_END)
    clearInterval(this.$store.state.timer)
    this.$store.state.dashboard.hideHomeMap = true
    if (this.mapTimer) clearTimeout(this.mapTimer)
  }

  @Watch('visualPersonList')
  watchVisualPersonList() {
    if (this.$store.state.dashboard.canScroll && this.isHomePage) {
      const dom = document.getElementsByClassName('el-scrollbar__wrap')[0] as HTMLDivElement
      backTop(dom)
    }
  }

  get showFengmap() {
    return this.$store.state.dashboard.hideHomeMap || this.startRotate || this.showDetailDialog
  }
  get gaugeValue() {
    return parseFloat(((this.realtimeNow / this.capacity) * 100).toFixed(2))
  }
  get oldRate() {
    return (this.customerRateToday - this.customerRate7) * 100
  }
  get stayRate() {
    return this.customerStayTodayToday - this.customerStayToday7
  }
  get visualPersonList() {
    const black: any[] = [],
      others: any[] = []
    this.$store.state.dashboard.personListFiltered.forEach((person: AnyObj) => {
      const type = getAttr(person, 'tags[0].type', false)
      if (type === 'black' && !person.edited) {
        black.push(person)
      } else {
        others.push(person)
      }
    })
    return [...black, ...others]
  }
  get todayStayTime() {
    const duration = moment.duration(this.customerStayTodayToday, 'seconds')
    const days = duration.get('days')
    const hour = duration.get('hours')
    const minute = duration.get('minutes')
    const second = duration.get('seconds')
    const res = `${days ? days + ',天,' : ''}${hour ? hour + ',小时,' : ''}${
      !days && minute ? minute + ',分钟,' : ''
    }${!days && !hour && second ? second + ',秒' : ''}${
      second <= 0 && !days && !hour && !minute ? '0,秒' : ''
    }`
    const arr = res.split(',')
    return arr
  }
  get timeType() {
    return timeType
  }

  private initOdometer() {
    const odometerOptions = {
      duration: 500,
      format: ''
    }

    const createOdometer = (selector: any, opts: any) =>
      //@ts-ignore
      new Odometer({
        el: document.querySelector(selector),
        ...odometerOptions,
        ...opts
      })

    this.odometers = [
      createOdometer('#dayKll', { value: 0 }),
      createOdometer('#oldOccupy', { value: 0, format: '(d).d' }),
      createOdometer('#todayStayTime1', { value: 0 }),
      createOdometer('#todayStayTime2', { value: 0 }),
      createOdometer('#gaugeValue', { value: 0, format: '(d).d' })
    ]
  }
  private updateOdometers(numbers: any[] = []) {
    this.odometers.map((odometer: any, i: any) => {
      if (typeof numbers[i] === 'number' && !isNaN(numbers[i]) && numbers[i] > 0) {
        odometer.update(numbers[i])
      } else {
        odometer.update(0)
      }
    })
  }
  private loadMap() {
    if (!this.showMap) {
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => (this.showMap = true), 2000)
      this.mapTimer = setTimeout(() => {
        if (!this.mapLoaded) {
          this.showMap = false
          this.mapLoaded = true
          this.message = this.$message({
            type: 'error',
            message: '地图加载失败，点击加载按钮重新加载。',
            customClass: 'is-underlying',
            duration: 0,
            showClose: true,
            clickButton: '加载',
            onClick: async () => {
              this.mapLoaded = false
              this.loadMap()
              this.message.close()
            },
            createElement: this.$createElement
          })
        }
      }, 20000)
    }
  }
  private loadComplete() {
    this.mapLoaded = true
    this.isSuccessLoad = true
    const boardStatus = this.currentStatus.boardStatus ? this.currentStatus.boardStatus : 'on'
    if (boardStatus === 'off') {
      this.$nextTick(() => {
        this.domPosition.button.x = 62
        this.domPosition.groupControl.x = 20
        if (this.indexConfig.map && this.indexConfig.map.precision === 'high') {
          if (this.startRotateNode.style) this.startRotateNode.style.right = '192px'
        } else {
          if (this.startRotateNode.style) this.startRotateNode.style.right = '302px'
        }
      })
    } else {
      this.$nextTick(() => {
        if (this.indexConfig.map && this.indexConfig.map.precision === 'high') {
          this.startRotateNode.style.right = '476px'
        } else {
          this.startRotateNode.style.right = '586px'
        }
      })
    }
  }
  private getScreen(data: any) {
    const { identity, areas } = data
    if (identity || areas[0]) {
      this.screenSelect = true
    } else {
      this.screenSelect = false
    }
    this.$store.state.dashboard.personFilter = data
  }
  private async getIndexConfig() {
    const res = this.$store.state.config
    if (Object.keys(res).length > 0) {
      this.indexConfig = res

      if (res.root.capacity === null || res.root.capacity === undefined) {
        this.capacity = 1000
      } else {
        this.capacity = res.root.capacity
      }
      if (res.root.mapId) {
        if (/geo:(\d{6})$/.test(res.root.mapId)) {
          this.isGeoMap = true
        } else {
          this.getHeatingfe()
          this.$store.dispatch(actionTypes.ACT_BASE_REALTIME_ADD, {
            fun: this.getHeatingfe,
            time: 3000,
            instance: this,
            name: 'realtime'
          })
        }
      } else {
        this.mapLoaded = true
      }
    }
  }
  private getDuration(seconds: any, type: any) {
    let res: any = '0'
    if (seconds === undefined) {
      res = 0 + '秒'
    } else {
      res = getDuration(seconds, type)
    }
    return res
  }
  private rateToFixed(num: number, n = 2) {
    if (!num) return 0
    return Math.floor(num * Math.pow(10, n)) / Math.pow(10, n)
  }
  private rate(today: number, last7: number) {
    if (!last7) return 0
    if (!today) today = 0
    return ((today - last7) / last7) * 100
  }
  private async cardClick(p: any) {
    const type = getAttr(p, 'tags[0].type', false)
    this.curPerson = { ...p }
    if (type === 'black') {
      p.edited = true
    }
    this.showDetailDialog = true
  }
  private errorClick() {
    this.curPerson.tags[0].type = 'error'
    this.editedClick()
  }
  private editedClick() {
    this.curPerson.edited = true
    this.showBlackPop = false
    this.showDetailDialog = true
  }
  private clearPersonList() {
    this.$store.state.dashboard.personList = []
  }
  private showScreenContent() {
    ;(this.$refs.screenNode as Vue).open()
  }
  private mouseEnter() {
    clearTimeout(this.timer)
    this.timer = null
    this.$store.state.dashboard.canScroll = false
  }
  private mouseLeave() {
    this.timer = setTimeout(() => {
      this.$store.state.dashboard.canScroll = true
    }, 2000)
  }
  //热力地图
  private async getHeatingfe() {
    try {
      const resp: any = await this.$api.base.getHeating()

      if (resp.data.error) {
        if (this.$route.path === '/') {
          const { message } = resp.data.error

          if (!this.heatingMessage) {
            this.heatingMessage = this.$message({
              type: 'error',
              message: `${message} 热力更新获取失败`,
              duration: 0,
              showClose: true
            })
          }
        }

        resp.hideMessage()
      } else {
        if (this.heatingMessage) this.heatingMessage.close()
        if (resp.data && resp.data.data) {
          this.areas = resp.data.data.heating
        }
      }
    } catch (err) {
      //this.$store.dispatch(actionTypes.ACT_BASE_REALTIME_END);
    }
  }
  //首页统计
  private async getHomeCount() {
    const opt = {
      startTime: this.$moment().startOf('day').format(),
      endTime: this.$moment().endOf('day').format()
    }
    return await this.$api.base.getIndexCount(opt)
  }
  //今日客流量，今日平均停留时间and今日老面孔占比
  private async getTodayke() {
    try {
      const resp: any = await this.getHomeCount()

      if (resp.data.error) {
        if (this.$route.path === '/') {
          const { message } = resp.data.error

          if (!this.todaykeMessage) {
            this.todaykeMessage = this.$message({
              type: 'error',
              message: `${message} 客流量更新获取失败`,
              duration: 0,
              showClose: true
            })
          }
        }

        resp.hideMessage()
      } else {
        if (this.todaykeMessage) this.todaykeMessage.close()
        if (resp.data && resp.data.data) {
          const res = resp.data.data
          this.realtimeTotal = res.passengerFlow[0].total
          const old = res.passengerFlow[0]
          this.customerStayTodayToday = res.stayTime[0].avg
          this.customerRateToday = old.total !== 0 ? old.identification.familiar / old.total : 0
          this.realtimeNow = res.realtimeNow[0].total
          const stay = this.todayStayTime
          const nums = [
            this.realtimeTotal,
            this.rateToFixed(this.customerRateToday * 100, 1),
            Number(stay[0]),
            Number(stay[2]),
            this.gaugeValue
          ]

          this.updateOdometers(nums)
        }
      }
    } catch (error) {
      if (error) {
        clearInterval(this.$store.state.timer)
      }
    }
  }
  //上周同日的平均停留时间and上周同日老面孔占比
  private async customerStayTodaySeven() {
    const opt = {
      startTime: this.$moment().add(-7, 'day').startOf('day').format(),
      endTime: this.$moment().add(-7, 'day').endOf('day').format()
    }
    const today7 = await this.$api.base.getIndexCount(opt)
    if (today7.data && today7.data.data) {
      const old = today7.data.data.passengerFlow[0]
      this.customerStayToday7 = today7.data.data.stayTime[0].avg
      this.customerRate7 = old.total !== 0 ? old.identification.familiar / old.total : 0
    }
  }

  private signInBoardHide() {
    ;(this.$refs.personList as Vue).style.right = '-270' + 'px'
    if (this.$refs.gaugeChart && (this.$refs.gaugeChart as Vue).childNodes) {
      ;(this.$refs.gaugeChart as Vue).childNodes[0].style.right = '0'
    }
    this.clearPersonList()
    this.siderInBtn = true
    this.$ws.close()
    this.currentAccount = localStorage.getItem('currentAccount')
    if (this.currentAccount) {
      this.currentStatus = JSON.parse(localStorage.getItem(this.currentAccount) as string)
    }
    if (!this.currentStatus) {
      this.currentStatus = {}
    }
    Object.assign(this.currentStatus, { boardStatus: 'off' })
    if (this.currentAccount) {
      localStorage.setItem(this.currentAccount, JSON.stringify(this.currentStatus))
    }
  }
  // 是因为初始化的时候通过地图渲染完成的回调去改变地图按钮的值
  private domValue() {
    this.domPosition.button.x = 62
    this.domPosition.groupControl.x = 20
    if (this.indexConfig.map && this.indexConfig.map.precision === 'high') {
      this.startRotateNode.style.right = '192px'
    } else {
      this.startRotateNode.style.right = '302px'
    }
  }

  private signInBoardShow() {
    ;(this.$refs.personList as Vue).style.right = '12px'
    if (this.$refs.gaugeChart && (this.$refs.gaugeChart as Vue).childNodes) {
      ;(this.$refs.gaugeChart as Vue).childNodes[0].style.right = '225' + 'px'
    }
    this.domPosition.button.x = 346
    this.domPosition.groupControl.x = 304
    if (this.indexConfig.map && this.indexConfig.map.precision === 'high') {
      this.startRotateNode.style.right = '476px'
    } else {
      this.startRotateNode.style.right = '586px'
    }
    this.siderInBtn = false

    this.$store.state.dashboard.startWsConnection()
    this.currentAccount = localStorage.getItem('currentAccount')
    if (this.currentAccount) {
      this.currentStatus = JSON.parse(localStorage.getItem(this.currentAccount) as string)
    }
    if (!this.currentStatus) {
      this.currentStatus = {}
    }
    Object.assign(this.currentStatus, { boardStatus: 'on' })
    if (this.currentAccount) {
      localStorage.setItem(this.currentAccount, JSON.stringify(this.currentStatus))
    }
  }
}
