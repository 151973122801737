










































import { Vue, Component, Prop } from 'vue-property-decorator'
import AreasSearch from '@/components/dashboard/areasSearch/index.vue'

@Component({
  components: { AreasSearch }
})
export default class HomeScreen extends Vue {
  @Prop({ required: false, default: '' }) project: string

  showFlag = false
  setAreas: any[] = []
  form: Obj<string, any> = {
    areas: [],
    temperature: [],
    identity: null
  }
  saveScreenData: Obj<string, any> = {
    areas: [],
    identity: null
  }
  temperature: any[] = []
  get tagAllSelectedStyle() {
    const dark = { backgroud: '#182025', color: '#15a87f' }
    const light = { backgroud: '#fff', color: '#1890ff' }
    const themeMap: any = {
      dark,
      light
    }
    const theme = this.$store.state.features.theme || 'dark'
    return themeMap[theme]
  }

  deactivated() {
    this.showFlag = false
  }

  async open() {
    this.showFlag = true
    this.$nextTick(async () => {
      const areasNode = this.$refs.areasNode as Vue

      areasNode.emptyText = ''
      areasNode.loading = true
      try {
        this.form.identity = this.saveScreenData.identity || null
        if (this.saveScreenData.areas) {
          this.setAreas = this.saveScreenData.areas
          await areasNode.init(this.setAreas.length > 0 ? this.setAreas : [])
        } else {
          await areasNode.init([])
        }
        areasNode.loading = false
        areasNode.emptyText = '暂无数据'
      } catch (error) {
        areasNode.loading = false
        areasNode.emptyText = '暂无数据'
      }
    })
  }

  getCascaderTags(val: any) {
    this.form.identity = val
  }

  selectAreas(areasIds: string[], allAreas: AnyObj) {
    this.form.areas = areasIds
    this.form.allAreas = allAreas
  }

  checkedTemperature() {
    this.form.temperature = this.temperature
  }

  cancelDialog() {
    this.showFlag = false
  }

  onSubmit() {
    this.$emit('getScreen', { ...this.form })
    this.showFlag = false
    this.saveScreenData = { ...this.form }
  }
}
