<template>
  <div class="geo-map">
    <div ref="map" class="map"></div>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { variables } from '@/libs/theme'
@Component
export default class GeoMap extends Vue {
  @Prop({ required: true }) config

  get mapId() {
    const { mapId } = this.config.root
    if (mapId && /geo:(\d{6})$/.test(mapId)) return /geo:(\d{6})$/.exec(mapId)[1]

    return 110000
  }

  get mapLabels() {
    const { children } = this.config

    if (Array.isArray(children)) {
      return children
        .filter(a => a.mapLabel && /geo:\((.+?)\)/g.test(a.mapLabel))
        .map(a => ({
          name: a.name,
          value: /\((.+?)\)/g
            .exec(a.mapLabel)[1]
            .split(',')
            .map(v => parseFloat(v))
            .concat([10])
        }))
    }

    return []
  }

  @Watch('config', { immediate: true, deep: true })
  onConfigChanged() {
    this.renderMap()
  }

  async renderMap() {
    const { data } = await this.$api.static.getGeoJson(this.mapId)

    if (data && !data.error) {
      echarts.registerMap('epidemic-map', data)

      const myChart = echarts.init(this.$refs.map)

      myChart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: ({ name, value }) =>
            `${name}: ${isNaN(value) ? '-' : Math.round(value * 100) / 100 + '‰'}`
        },
        geo: {
          show: true,
          map: 'epidemic-map',
          layoutCenter: ['50%', '50%'],
          layoutSize: '60%',
          label: {
            show: true,
            color: [variables.whiteColor]
          },
          itemStyle: {
            borderColor: '#ccc',
            borderWidth: 1.6,
            areaColor: 'transparent'
          },
          emphasis: {
            label: {
              show: false,
              color: 'fff'
            },
            itemStyle: {
              areaColor: [variables.green24Color]
            }
          }
        },
        series: [
          {
            name: 'sans',
            type: 'scatter',
            coordinateSystem: 'geo',
            symbol: 'image://' + require('@/assets/images/dashboard/epidemic_map_mark.png'),
            symbolSize: [26, 34],
            symbolOffset: [0, '-50%'],
            tooltip: {
              trigger: 'item',
              formatter: ({ name }) => name
            },
            itemStyle: {
              color: [variables.green24Color],
              opacity: 0.9
            },
            geoIndex: 0,
            data: this.mapLabels
          }
        ]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.geo-map {
  width: calc(100% - 270px);
  height: 100%;

  > .map {
    height: 100%;
  }
}
</style>
