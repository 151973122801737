<script>
/* eslint-disable @typescript-eslint/camelcase */
import ECharts from '@/components/common/Echarts/echart'
import { variables, rgba } from '@/libs/theme'

export default {
  data() {
    return {
      option: {},
      init_option: {
        // renderer: 'svg'
      }
    }
  },
  props: {
    value: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    count: {
      required: false
    },
    parentId: {
      type: String,
      required: false
    }
  },
  activated() {
    this.update()
  },
  methods: {
    update() {
      const demoData = [
        {
          name: '场内热度',
          value: '',
          unit: '',
          pos: ['50%', '50%'],
          range: [0, 100],
          colora: [variables.green7Color],
          colorb: [variables.green15Color]
        }
      ]
      const option = {
        series: (() => {
          const result = []

          demoData.forEach(item => {
            result.push(
              // 内侧指针、数值显示
              {
                name: item.name,
                type: 'gauge',
                center: item.pos,
                radius: '70%',
                startAngle: 225,
                endAngle: -45,
                fontWeight: '920',
                min: 0,
                max: 100,
                axisLine: {
                  show: true,
                  lineStyle: {
                    width: 2,
                    color: [
                      [
                        this.value / 100,
                        new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                          {
                            offset: 0,
                            color: [rgba(variables.colorPrimary, 0.14)]
                          },
                          {
                            offset: 1,
                            color: [rgba(variables.colorPrimary, 0.69)]
                          }
                        ])
                      ],
                      [1, [variables.transparent]]
                    ]
                  }
                },
                axisTick: {
                  show: 0
                },
                splitLine: {
                  show: 0
                },
                axisLabel: {
                  show: 0
                },
                pointer: {
                  show: 0
                },
                detail: {
                  show: 0
                },
                data: [
                  {
                    value: this.value
                  }
                ]
              },
              // 外围刻度
              {
                type: 'gauge',
                center: item.pos,
                radius: '60%', // 1行3个
                splitNumber: item.splitNum || 10,
                min: 0,
                max: 100,
                startAngle: 225,
                endAngle: -45,
                axisLine: {
                  show: true,
                  lineStyle: {
                    width: 1,
                    color: [[1, [variables.blackColor]]]
                  }
                }, //仪表盘轴线
                axisTick: {
                  show: true,
                  length: 2,
                  lineStyle: {
                    color: [variables.blackColor],
                    width: 1
                  }
                }, //刻度样式
                splitLine: {
                  show: true,
                  length: 5,
                  lineStyle: {
                    color: [variables.blackColor],
                    fontWeight: 'normal'
                  }
                }, //分隔线样式
                axisLabel: {
                  show: true,
                  distance: 2,
                  textStyle: {
                    color: [variables.colorPrimary],
                    fontSize: '10',
                    fontWeight: 'normal'
                  }
                },
                pointer: {
                  show: 0
                },
                detail: {
                  show: 0
                }
              },

              // 内侧指针、数值显示
              {
                name: item.name,
                type: 'gauge',
                center: item.pos,
                radius: '64%',
                startAngle: 225,
                endAngle: -45,
                min: 0,
                max: 100,
                axisLine: {
                  show: true,
                  lineStyle: {
                    width: 20,
                    color: [
                      [
                        this.value / 100,
                        new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                          {
                            offset: 0,
                            color: [variables.transparent]
                          },
                          {
                            offset: 1,
                            color: [rgba(variables.colorPrimary, 0.69)]
                          }
                        ])
                      ],
                      [1, [variables.transparent]]
                    ]
                  }
                },
                axisTick: {
                  show: 0
                },
                splitLine: {
                  show: 0
                },
                axisLabel: {
                  show: 0
                },
                pointer: {
                  show: false,
                  length: '102%',
                  width: 3
                },
                title: {
                  show: true,
                  offsetCenter: [0, '-25%'],
                  fontWeight: '320',
                  color: [variables.textColor],
                  fontSize: 15,
                  borderRadius: 21,
                  padding: 5
                },
                detail: {
                  show: false,
                  offsetCenter: [2, 20],
                  textStyle: {
                    fontSize: 22,
                    color: [variables.blackColor]
                  },
                  formatter: [
                    '{value}' + (item.unit || '')
                    /* '{name|' + item.name + '}'*/
                  ].join('\n'),

                  rich: {
                    name: {
                      fontSize: 20,
                      lineHeight: 10,
                      color: '#ddd',
                      padding: [30, 0, 0, 0]
                    }
                  }
                },
                itemStyle: {
                  normal: {
                    color: [variables.colorPrimary]
                  }
                },
                data: [
                  {
                    value: '0',
                    name: item.name
                  }
                ]
              }
            )
          })

          return result
        })()
      }
      this.option = option
    }
  },
  watch: {
    value() {
      this.update()
    }
  },
  components: {
    ECharts
  }
}
</script>
<template>
  <div>
    <e-charts
      :option="option"
      :init_option="init_option"
      :auto_load="true"
      :parentId="parentId"
    ></e-charts>
    <slot />
  </div>
</template>
