




















import { Vue, Component } from 'vue-property-decorator'
import store, { actionTypes } from '@/store'

@Component
export default class AreasSearch extends Vue {
  selectArea: AnyObj[] = []
  areasId: string[] = []
  loading = false
  emptyText = ''

  async getAreasList() {
    this.selectArea = []
    const areas = await store.dispatch(actionTypes.FETCH_BASE_AREAS)
    if (areas) {
      this.selectArea = areas
    }
  }
  check(data: any, obj: any) {
    // 只取所有被选中的父元素
    let temp = [...obj.checkedKeys]
    for (let i = 0; i < temp.length; i++) {
      const child = this.getChildren(this.selectArea, temp[i])
      temp = temp.filter(item => {
        return !child.includes(item)
      })
    }
    this.$emit('selectAreas', temp, this.selectArea)
  }
  getChildren(data: any, id: string) {
    let children: any[] = []
    const getIds = (arr: any, ids: any = []) => {
      arr.forEach((item: AnyObj) => {
        ids.push(item.id)
        if (item.children) {
          getIds(item.children, ids)
        }
      })
      return ids
    }
    const rev = (value: any, nodeId: string) => {
      value.forEach((obj: any) => {
        if (obj.id === nodeId) {
          if (obj.children) {
            children = getIds(obj.children)
          }
          return
        }
        if (obj.children) {
          rev(obj.children, nodeId)
        }
      })
      return children
    }
    children = rev(data, id)
    return children
  }
  async init(val: string[]) {
    await this.getAreasList()
    const areaAll = val || []
    this.$nextTick(() => {
      const editTreeNode = this.$refs.editTreeNode as Vue
      editTreeNode.setCheckedKeys(areaAll)
      this.$emit('selectAreas', areaAll, this.selectArea)
    })
  }
}
