



























import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import FengMap from '@/components/common/FengMap/index.vue'
import { variables } from '@/libs/theme'
@Component({
  name: 'homePage',
  components: {
    FengMap
  }
})
export default class AutoRotate extends Vue {
  @Ref() readonly fengmapNode: Vue
  @Prop({ default: false }) private showRotate: boolean
  @Prop({ default: false }) private heating: any[]
  private shadow9Color = variables.shadow9Color
  private showMap = false
  private mapLoaded = false
  private domPosition = {
    groupControl: { x: 40, y: 40, type: 'RIGHT_BOTTOM' }
  }
  private mapTimer: null | number = null
  mounted() {
    this.loadMap()
  }
  deactivated() {
    this.$emit('close')
  }
  beforeDestroy() {
    if (this.mapTimer) clearTimeout(this.mapTimer)
    this.fengmapNode.dispose()
  }
  loadMap() {
    if (!this.showMap) {
      this.showMap = true
      this.mapTimer = window.setTimeout(() => {
        if (!this.mapLoaded) {
          this.showMap = false
          this.mapLoaded = true
          this.message = this.$message({
            type: 'error',
            message: '地图加载失败，点击加载按钮重新加载。',
            customClass: 'is-underlying',
            duration: 0,
            showClose: true,
            clickButton: '加载',
            onClick: async () => {
              this.mapLoaded = false
              this.loadMap()
              this.message.close()
            },
            createElement: this.$createElement
          })
        }
      }, 10000)
    }
  }
  loadComplete() {
    this.mapLoaded = true
  }
  endRotate() {
    this.$emit('close')
  }
}
