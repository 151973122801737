




















































































import { Vue, Component, Prop } from 'vue-property-decorator'
import moment from 'moment'
import FeatureImg from '@/components/dashboard/FeatureImg/index.vue'
import { ageGroup, parseGender, repoType, getAttr, getDuration, timeType } from '@/utils'
import CopyText from '@/components/common/CopyText.vue'
import SecurityText from '@/components/common/SecurityText/index.vue'

@Component({
  components: { FeatureImg, CopyText, SecurityText }
})
export default class HomeScreen extends Vue {
  @Prop({ required: true }) person: AnyObj

  get typeClass() {
    return this.personType ? this.personType + '-card' : ''
  }

  get personType() {
    const attr = this.getAttr(this.person, 'tags[0].type', false)
    return attr ? this.getAttr(this.person, 'tags[0].type', false) : 'strange'
  }

  get approachTime() {
    return this.getMin(this.person.areas[0].startTime, this.person.capture.time)
  }

  get getSmallArea() {
    return this.smallArea(this.person.areas)
  }

  get tipContent() {
    return this.pathArea.join('/')
  }

  get pathArea() {
    const deleShift: any[] = this.person.areas.slice(1)
    const res = deleShift.map(r => r.name)
    res.pop() // 删掉最后一个元素
    return res
  }

  smallArea(list: AnyObj[]) {
    let smallA
    list.map(({ id, name }) => {
      if (this.person.capture.area.parent === id) {
        smallA = name
      } else {
        smallA = this.person.capture.area.name
      }
    })
    return smallA
  }

  getAttr(obj: any, props: string, def: boolean) {
    return getAttr(obj, props, def)
  }

  repoType(type: any) {
    return repoType(type)
  }

  ageGroup(age: number) {
    return ageGroup(age)
  }

  parseGender(gender: any) {
    return parseGender(gender)
  }

  getTimes(time: string) {
    // 获取时间戳
    return moment(time, 'YYYY-MM-DD HH:mm:ss').valueOf()
  }

  getMin(s1: string, s2: string) {
    const ms = this.getTimes(s2) - this.getTimes(s1)
    return getDuration(Math.floor(ms / 1000), timeType.A_SECTIONS, true)
  }
}
